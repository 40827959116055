//IMPORTS
import React, { useState } from 'react';
import { twMerge } from 'tailwind-merge';
import { classes } from '@components/Inputs/Classes';
import Send from '~icons/carbon/send';
import SendFilled from '~icons/carbon/send-filled';
import View from '~icons/carbon/view';
import ViewOff from '~icons/carbon/view-off';

//EXPORT
/**
 * Formatted input component
 *
 * @remarks
 * Must be hydrated for active styling
 * @param button - Includes an inline submit or password view button
 */
export default function Input({
	button,
	className,
	dark = 'inverse',
	disabled = false,
	inline = false,
	labelClassName,
	required = false,
	style = 'primary',
	type = 'text',
	...attrs
}: {
	placeholder: string;
	name: string;
	button?: 'password' | 'submit';
	className?: string;
	dark?: 'true' | 'false' | 'auto' | 'inverse';
	disabled?: boolean;
	inline?: boolean;
	labelClassName?: string;
	required?: boolean;
	style?: 'primary' | 'secondary';
	type?: React.InputHTMLAttributes['type'];
}) {
	//USESTATES
	const [focused, setFocused] = useState(false);
	const [passwordView, setPasswordView] = useState(true);

	return (
		<div
			className={twMerge(
				//INLINE STYLING
				inline ? 'flex border-x-0 border-y' : 'rounded border',
				//CONSISTENT INPUTS FORMATTING
				classes(style, dark, disabled, focused),

				//CUSTOM STYLING
				className,
			)}
		>
			{/* FORMATTED INPUT FIELD */}
			<input
				className={twMerge(
					//GENERAL
					'w-full border-0',
					//COMPONENTS CONSISTENT FORMATTING
					classes(style, dark, disabled),
					//CUSTOM
					labelClassName,
				)}
				{...attrs}
				type={
					type == 'password' ?
						passwordView ?
							'password'
						:	'text'
					:	type
				}
				disabled={disabled}
				autoCapitalize='none'
				onFocus={() => setFocused(true)}
				onBlur={() => setFocused(false)}
				required={required}
			/>
			{/* VIEW PASSWORD BUTTON */}
			{button === 'password' && (
				<div
					className={twMerge(
						'inline px-2 text-inherit',
						focused ?
							style == 'secondary' ?
								'text-secondary-700'
							:	'text-primary-700'
						: style == 'secondary' ? 'text-secondary-700/50'
						: 'text-primary-700/50',
						disabled && 'text-primary-700/40',
					)}
				>
					{passwordView ?
						<ViewOff onClick={() => setPasswordView(false)} />
					:	<View onClick={() => setPasswordView(true)} />}
				</div>
			)}
			{/* SUBMIT BUTTON */}
			{button === 'submit' && (
				<button
					disabled={disabled}
					type='submit'
					className={twMerge(
						'group my-1 mr-2 !bg-transparent !text-primary-700 !shadow-none',
						disabled && 'text-primary-700/40',
					)}
				>
					{/* DEFAULT BUTTON */}
					<span className={twMerge('flex h-full items-center', !disabled && 'group-hover:hidden')}>
						<Send
							className={twMerge(
								'inline align-[-.16em] text-2xl',
								disabled && 'cursor-not-allowed text-primary-700/40',
							)}
						/>
					</span>
					{/* ACTIVE BUTTON */}
					<span className={twMerge('hidden h-full items-center', !disabled && 'group-hover:flex')}>
						<SendFilled
							className={twMerge(
								'inline align-[-.16em] text-2xl',
								disabled && 'cursor-not-allowed text-primary-700/40',
							)}
						/>
					</span>
				</button>
			)}
		</div>
	);
}
